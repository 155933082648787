<template>
    <div class="mainPage">
        <div class="mainPageContent">
            <div class="contentPanel">
                <div class="headerLogo">
                    <img src="../assets/logo_b.png"
                         alt="Osmose"
                         @click="openDashboard()" />
                </div>
                <a :href="supportURL" target="_blank" class="osmoseUrl">
                    {{
          supportURL
                    }}
                </a>
                <div class="textCenter info1">
                    <div>Device ID:</div>
                    <div class="textnormal">
                        {{ deviceId ? deviceId : "Not Applicable" }}
                    </div>
                </div>
                <div class="contactSection">
                    <div class="textCenter info1">Contact us for Support</div>
                    <a :href="'mailto: ' + supportEmail" class="textCenter info2">
                        {{
            supportEmail
                        }}
                    </a><br />
                    <br />
                    <a :href="'tel: ' + supportPhone" class="textCenter info2 mgTop12">
                        {{
            supportPhone
                        }}
                    </a>
                </div>
                <div class="textCenter">
                    <a href="javascript: void(0);"
                       class="backLink txtUppercase"
                       @click="onCancel()">
                        CANCEL
                    </a>
                </div>
                <div class="textCenter" v-if="erudaInActive">
                    <br />
                    <br />
                    <a href="javascript: void(0);"
                       class="backLink txtUppercase"
                       @click="onUserSupport()">
                        User Support Access
                    </a>
                </div>
                <div class="textCenter" v-if="erudaActive">
                    <br />
                    <br />
                    <a href="javascript: void(0);"
                       class="backLink txtUppercase"
                       @click="onUserStopSupport()">
                        Turn Off User Support Access
                    </a>
                </div>
            </div>

            <div class="bottomFixed">
                <ul class="listInline">
                    <!--<li v-if="showSettings" class="listItem">
                        <a href="javascript: void(0);" @click="openSettings()">
                            SETTINGS
                        </a>
                    </li>-->
                    <!--<li class="listItem">
                        <a class="active" href="javascript: void(0);">SUPPORT</a>
                    </li>-->
                </ul>
            </div>

            <div class="popUp" v-if="popup">
                <div class="popUpBox">
                    <p class="popupText">{{ popupMsg }}</p>
                    <label style="margin-bottom:15px">Password </label>
                    
                    <input title=""
                           type="text"
                           width="4"
                           v-model="password"
                           :class="[
                    'formControl',
                    'spinNone',
                  ]"
                           style="padding-top:5px; width:60px"
                           placeholder="Enter Value" />
                    <div />

                    <div class="modalFooter" style="margin-top:10px">
                        <button class="btnRounded" v-on:click="onClosePrompt()">
                            CANCEL
                        </button>
                        <button class="btnRoundedFull" v-on:click="onConfirmPrompt()">
                            {{ popupBtnTxt }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Eruda from 'eruda';

export default {
  name: "Support",

  data: () => ({
    showSettings: true,
      deviceId: localStorage.getItem("deviceID"),
      password: "0000",
      popupMsg: "Enable Debug Tools",
      popupBtnTxt: "Yes",
      popup: false,
      erudaActive: false,
      erudaInActive: true,
  }),

  mounted() {
    if (this.$route.params.showSettings !== undefined)
        this.showSettings = this.$route.params.showSettings;
      if (localStorage.getItem("ErudaEnabled") === "Yes") {
          Eruda.init();
          this.erudaActive= true;
          this.erudaInActive= false;
      }
  },

  methods: {
    openDashboard() {
      this.$router.replace("/dashboard");
    },

      onCancel() {
          //debugger;
      this.$router.go(-1);
      },

      onUserSupport() {
          this.popup = true;
      },
      onClosePrompt() {
          this.password = "";
          this.popup = false;
      },
      onConfirmPrompt() {
          let pw = this.deviceId.substring(0, 4);

          //debugger;
          if (this.password === pw) {
              localStorage.setItem("ErudaEnabled", "Yes");
              this.erudaActive = true;
              this.erudaInActive = false;
              Eruda.init();
          }
          else {
              alert("Password not correct.");
          }
          this.popup = false;
      },

      onUserStopSupport() {

            localStorage.setItem("ErudaEnabled", "No");
          this.popup = false;
          window.location.reload();
      },
      
    openSettings() {
      this.$router.push("/settings");
    },
  },
  computed: {
    supportURL() {
      //return the SUPPORT URL
      return process.env.VUE_APP_SUPPORT_URL;
    },
    supportEmail() {
      //return the SUPPORT EMAIL
      return process.env.VUE_APP_SUPPORT_EMAIL;
    },
    supportPhone() {
      //return the SUPPORT PHONE
      return process.env.VUE_APP_SUPPORT_PHONE;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.textnormal {
  font-weight: normal !important;
}
.osmoseUrl {
  text-align: center;
  color: #2c95ff;
  font-size: 12px;
  display: block;
  margin-top: 10px;
}
.popUp {
  text-align: center;
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.popUpBox {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 5px #d2e4ff;
  padding: 10px 10px 25px 10px;
  width: 230px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  p {
    font-weight: 600;
  }
}
.contactSection {
  margin: 12% 0px;
}
.btnRounded {
  background: #f1f1f1;
  border: 2px solid #f1f1f1;
  padding: 6px;
  border-radius: 50px;
  color: #2c95ff;
  min-width: 90px;
  cursor: pointer;
}
.btnRoundedFull {
  background: #2c95ff;
  border: 2px solid #2c95ff;
  padding: 6px;
  border-radius: 50px;
  color: #fff;
  min-width: 90px;
  cursor: pointer;
}
.mainPage {
  height: calc(100vh - 50px);
}
.headerLogo {
  img {
    width: 230px;
  }
}
.mainPageContent {
  margin: auto;
}
.listInline {
  list-style: none;
  padding-left: 0;
  font-size: 14px;
  margin: 1rem 0px;
  .listItem {
    display: inline-block;
    margin-right: 1rem;
    a {
      text-decoration: none;
    }
    .active {
      font-weight: 700;
    }
  }
  .listItem:last-child {
    margin-right: 0rem;
  }
}

.title1 {
  margin: 3% 0px;
  text-align: center;
  color: #003468;
  font-weight: 600;
  font-size: 20px;
}
.btndef {
  background: #ccc;
  border-radius: 5px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  border: 0px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin: 18px 0px;
  pointer-events: none;
}
.btnblue {
  background: #003468;
  border-radius: 5px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  border: 0px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin: 18px 0px;
  pointer-events: all !important;
}
.error {
  color: #f00;
  font-size: 12px;
}
.bottomFixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  background: #fff;
  z-index: 1;
  .listInline a {
    color: #003468;
  }
}
.backLink {
  color: #003468;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
}
.contentPanel {
  width: 350px;
  text-align: center;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 50px;
}
.info1 {
  color: #003468;
  font-size: 14px;
  font-weight: 600;
  margin: 16px 0px;
}
.info2 {
  color: #2c95ff;
  font-size: 14px;
  font-weight: 600;
  margin: 16px 0px;
}
//Tabs Only
@media (min-width: 768px) {
  .contentPanel {
    padding-top: 5rem;
  }

  .listInline {
    margin: 2rem 0px;
  }
  //   .headerLogo{
  //   img{
  //     width: 230px;
  //     height: 48px;
  //   }
  // }
}
//Desktop only
@media (min-width: 1200px) {
  .listInline {
    margin: 1rem 0px;
  }
  .contentPanel {
    padding-top: 1rem;
  }
  .title1 {
    margin: 3% 0px;
  }
}

//Small mobile
@media (max-width: 374.5px) {
  .contentPanel {
    width: 90% !important;
  }
}
</style>
